<template>
  <div>
    <div
      class="form--group"
      :class="{
        'has-error': validation.hasError('latitude'),
      }"
      v-show="!autocompleteQuery || $mq === 'xs'"
    >
      <div class="left--col">
        <div class="d-flex align-items-center">
          <label for="search-place-field">
            <span>{{ $t('addListing.pin.title') }}</span>
            <span class="required">*</span>
          </label>
          <img src="@/assets/img/icons/info.svg" class="info--icon" id="mapInfo" />
          <PopOverListingForm
            target="mapInfo"
            :content="
              listingType === 'R' ? $t('addListing.pin.message') : $t('addListing.pin.messageS')
            "
          />
        </div>
      </div>
      <div class="right--col">
        <search-places
          class="input--margin"
          @placeDetailFound="placeDetailFound"
          ref="searchPlacesRef"
        />
        <span class="val-error" v-if="validation.hasError('latitude')">{{
          validation.firstError('latitude')
        }}</span>
      </div>
    </div>
    <listing-form-transition>
      <div v-show="openAddressSection" style="position: relative;">
        <section-loading :show="loadingMap" />
        <div style="position: relative;">
          <div class="map--container" id="propertyMap"></div>
          <div class="location--overlay" v-show="autocompleteQuery && $mq !== 'xs'">
            <div class="row align-items-center">
              <div class="col">
                {{ autocompleteQuery }}
              </div>
              <div class="col-auto close--btn" @click="clearQuery">
                <span class="ion-close"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </listing-form-transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import LodashMixin from '@/mixins/lodash';
import PopOverListingForm from '@/components/listing-form/pop-over-listing-form';
import ListingFormTransition from '@/components/listing-form/listing-form-transition';
import SectionLoading from '@/components/content-loading/section-loading';

const SearchPlaces = () => import('@/components/listing-form/sect1/map/search-places.vue');
export default {
  name: 'map-section',
  components: { SearchPlaces, PopOverListingForm, ListingFormTransition, SectionLoading },
  mixins: [LodashMixin],
  data() {
    return {
      icon: {
        url: '/img/map_pin.png', // url
        // eslint-disable-next-line no-undef
        scaledSize: new google.maps.Size(50, 50), // scaled size
        // eslint-disable-next-line no-undef
        origin: new google.maps.Point(0, 0), // origin
        // eslint-disable-next-line no-undef
        anchor: new google.maps.Point(25, 50), // anchor
      },
      map: null,
      marker: null,
      detailZoom: 18,
      overallZoom: 10,
      mapCreated: false,
      allowGeocode: true,
      sgCoords: [],
      sgBounds: null,
      lastValidCenter: null,
      lastValidMarker: null,
    };
  },
  beforeCreate() {
    this.latitude = null;
    this.longitude = null;
  },
  created() {
    this.debouncedLatLongByGeocode = this.debounce(this.getLatLongByGeocode, 500);
    if (this.form === 'edit') {
      this.allowGeocode = false;
    }
  },
  async mounted() {
    console.log('mounted map');

    const result = await this.$http.get(
      'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/assets/sgbounds.json',
      {
        headers: null,
      },
    );
    if (result) {
      // console.log(result.data);
      this.sgCoords = result.data;
    }
    if (this.form === 'add') {
      this.initMap();
    } else {
      this.setLoading(true);
      await this.$store.dispatch('v2/listingForm/fetchBaseData');
      this.$store.dispatch('v2/listingForm/sect1/openAllHiddenForms');
      this.setLoading(false);
      this.$nextTick(() => {
        this.initMap();
      });
    }
  },
  computed: {
    ...mapState({
      form: state => state.v2.listingForm.form,
      province: state => state.regional.province,
      city: state => state.regional.city,
      loadingMap: state => state.v2.listingForm.sect1.location.loadingMap,
      openAddressSection: state => state.v2.listingForm.sect1.openAddressSection,
      loadPropertyDataFromList: state =>
        state.v2.listingForm.sect1.location.loadPropertyDataFromList,
      fetchingEditListingData: state => state.v2.listingForm.fetchingEditListingData,
      listingType: state => state.v2.listingForm.listingType,
      autocompleteQuery: state => state.v2.listingForm.sect1.location.autocompleteQuery,
    }),
    geocodeQuery() {
      const city = this.city ? this.city.name : '';
      const province = this.province ? this.province.name : '';
      return `${city} ${province}`;
    },
    latitude: {
      get() {
        return this.$store.state.v2.listingForm.sect1.location.latitude;
      },
      set(val) {
        this.$store.commit('v2/listingForm/sect1/location/CHANGE_LATITUDE', val);
      },
    },
    longitude: {
      get() {
        return this.$store.state.v2.listingForm.sect1.location.longitude;
      },
      set(val) {
        this.$store.commit('v2/listingForm/sect1/location/CHANGE_LONGITUDE', val);
      },
    },
  },
  watch: {
    // fetchingEditListingData(value){
    //     if(!value){
    //         if(this.form==='edit'){
    //             this.initMap();
    //         }
    //     }
    // },
    geocodeQuery(value) {
      if (!this.loadPropertyDataFromList && this.allowGeocode) {
        this.debouncedLatLongByGeocode(value);
      }
    },
    latitude(val, oldVal) {
      if (oldVal !== '') {
        this.changeLocationByLatLong(this.latitude, this.longitude);
      }
    },
    longitude(val, oldVal) {
      if (oldVal !== '') {
        this.changeLocationByLatLong(this.latitude, this.longitude);
      }
    },
    // openAddressSection(value) {
    //   if (value === true) {
    //     if (this.latitude === null) {
    //       this.latitude = ;
    //     }
    //     this.debouncedLatLongByGeocode(value);
    //   }
    // },
  },
  validators: {
    latitude(value) {
      return Validator.value(value).required(this.$i18n.t('errors.latitude.required'));
    },
  },
  methods: {
    validate: function() {
      return this.$validate().then(
        function(success) {
          return !!success;
        }.bind(this),
      );
    },
    setLoading(value) {
      this.$store.commit('v2/listingForm/SET_LOADING', value);
    },
    clearQuery() {
      this.$store.commit('v2/listingForm/sect1/location/SET_AUTO_COMPLETE_QUERY', null);
      let searchPlacesRef = this.$refs.searchPlacesRef;
      if (searchPlacesRef) {
        searchPlacesRef.clearQuery();
      }
    },
    placeDetailFound() {
      this.map.setZoom(this.detailZoom);
    },
    changeLocationByLatLong(lat, lng) {
      if (this.mapCreated) {
        // eslint-disable-next-line no-undef
        const latlng = new google.maps.LatLng(lat, lng);
        this.marker.setPosition(latlng);
        this.map.panTo(latlng);
        this.lastValidMarker = latlng;
        this.lastValidCenter = latlng;
      }
    },
    getLatLongByGeocode(searchQuery) {
      // eslint-disable-next-line no-undef
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          address: searchQuery,
        },
        (results, status) => {
          // eslint-disable-next-line no-undef
          if (status === google.maps.GeocoderStatus.OK) {
            const resultJson = results[0].geometry.location.toJSON(),
              lng = resultJson.lng;
            this.latitude = resultJson.lat;
            this.longitude = lng;
            this.map.setZoom(this.overallZoom);
          }
        },
      );
    },
    getFitBound() {
      // eslint-disable-next-line no-undef
      const bound = new google.maps.LatLngBounds();
      bound.extend(this.marker.position);
      return bound;
    },
    getUserLocation() {
      return new Promise(resolve => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            position => {
              (this.latitude = position.coords.latitude),
                (this.longitude = position.coords.longitude);
              resolve();
            },
            function() {
              resolve();
            },
          );
        } else {
          resolve();
        }
      });
    },
    initMarker() {
      // eslint-disable-next-line no-undef
      this.marker = new google.maps.Marker({
        position: {
          lat: this.latitude !== null ? this.latitude : 1.3102849,
          lng: this.longitude !== null ? this.longitude : 103.8035589,
        },
        icon: this.icon,
        map: this.map,
        draggable: true,
        // eslint-disable-next-line no-undef
        animation: google.maps.Animation.DROP,
      });
      this.lastValidMarker = this.marker.getPosition();
      this.marker.addListener('dragend', event => {
        const self = this;
        // eslint-disable-next-line no-undef
        const isInside = google.maps.geometry.poly.containsLocation(event.latLng, this.sgBounds);
        if (isInside) {
          this.allowGeocode = false;
          this.latitude = event.latLng.lat();
          this.longitude = event.latLng.lng();

          console.log('lastValidCenter', this.lastValidCenter);
          console.log('eventlatlng', event.latLng);
          // eslint-disable-next-line no-undef
          google.maps.event.addListenerOnce(this.map, 'bounds_changed', function() {
            if (this.getZoom() > self.detailZoom) {
              this.setZoom(self.detailZoom);
            }
          });
          this.map.fitBounds(this.getFitBound());
          this.lastValidMarker = event.latLng;
          return;
        }
        this.marker.setPosition(this.lastValidMarker);
        this.map.panTo(this.lastValidCenter);
      });
    },
    async initMap() {
      let zoom = 10;
      if (this.form === 'add') {
        // await this.getUserLocation();
      } else {
        zoom = this.detailZoom;
      }

      // eslint-disable-next-line no-undef
      google.maps.Polygon.prototype.getBoundingBox = function() {
        // eslint-disable-next-line no-undef
        var bounds = new google.maps.LatLngBounds();
        this.getPath().forEach(function(element) {
          bounds.extend(element);
        });
        return bounds;
      };

      // Construct the polygon.
      // eslint-disable-next-line no-undef
      this.sgBounds = new google.maps.Polygon({
        paths: this.sgCoords,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
      });

      const options = {
        center: {
          lat: this.latitude !== null ? this.latitude : 1.3102849,
          lng: this.longitude !== null ? this.longitude : 103.8035589,
        },
        zoom: zoom,
        zoomControl: true,
        zoomControlOptions: {
          // eslint-disable-next-line no-undef
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        streetViewControl: false,
        maxZoom: 18,
      };
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById('propertyMap'), options);
      this.mapCreated = true;
      this.initMarker();
      this.lastValidCenter = this.map.getCenter();
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.map, 'center_changed', () => {
        if (this.sgBounds.getBoundingBox().contains(this.map.getCenter())) {
          console.log('masuk sini');
          // still within valid bounds, so save the last valid position
          this.lastValidCenter = this.map.getCenter();
          return;
        }
        console.log('masuk sana');

        // not valid anymore => return to last valid position
        this.map.panTo(this.lastValidCenter);
      });
    },
  },
};
</script>

<style scoped></style>
